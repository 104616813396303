//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.table td {
	border-style: dashed;
}

.underline-menu-contract:hover {
	text-decoration: underline;
	cursor: pointer;
}

.card-hover:hover {
	cursor: pointer;
	box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1);
	border-radius: 8px;
}

.custom-placeholder::placeholder {
	color: white !important;
	opacity: 1;
}

.custom-placeholder::-ms-input-placeholder {
	color: white !important;
}

.ql-container {
	min-height: 10rem;
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.ql-editor {
	height: 100%;
	flex: 1;
	overflow-y: auto;
	width: 100%;
}
